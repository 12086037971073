<template>
  <div>
    <v-card>
      <v-card-title> {{ $t("systemSettings.title") }}</v-card-title>
      <v-card-text>
        <v-tabs v-model="activeTab">
         <v-tab v-if="$store.getters.isAdmin">{{ $t("systemSettings.tabs.languages") }}</v-tab>
<!--          <v-tab>Uprawnienia</v-tab>-->
          <v-tab v-if="$permission('SystemLogs')">{{ $t("systemSettings.tabs.logs") }}</v-tab>
          <v-tab v-if="$permission('CompanyInvite')">{{ $t("systemSettings.tabs.companyInvite") }}</v-tab>
          <v-tab v-if="$store.getters.isAdmin">{{ $t("systemSettings.tabs.currencies") }}</v-tab>
          <v-tab v-if="$store.getters.isAdmin">{{ $t('userProfile.tabs.permissions')}}</v-tab>
          <v-tab v-if="$store.getters.isAdmin">{{ $t('systemSettings.tabs.appTokens')}}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="activeTab">
          <v-tab-item v-if="$store.getters.isAdmin">
            <Languages />
          </v-tab-item>
          <v-tab-item v-if="$permission('SystemLogs')">
            <Logs />
          </v-tab-item>
           <v-tab-item v-if="$permission('CompanyInvite')">
            <CompanyInvite />
          </v-tab-item>
          <v-tab-item v-if="$store.getters.isAdmin">
            <Currencies />
          </v-tab-item>
          <v-tab-item v-if="$store.getters.isAdmin">
            <Permissions />
          </v-tab-item>
          <v-tab-item v-if="$store.getters.isAdmin">
            <AppTokens />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'System',
  components: {
    Languages: () => import('./Tabs/Languages.vue'),
    Logs: () => import('./Tabs/Logs.vue'),
    CompanyInvite: () => import('./Tabs/CompanyInvite.vue'),
    Currencies: () => import('./Tabs/Currencies.vue'),
    Permissions: () => import('./Tabs/Permissions.vue'),
    AppTokens: () => import('./Tabs/AppTokens.vue')
  },
  data () {
    return {
      activeTab: null
    }
  }
}
</script>
